import React, { useEffect, useState } from 'react';
import './resources.css';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from '../Dashboard/components/appbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, InputAdornment, Paper, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getBoolenYesOrNO, getDateFromString } from '../../utils/utils.func';

function ResourcesDetail() {   
    const navigate = useNavigate();
    const [loader, setloader] = useState(true);
    const [resourceData, setResourceData] = React.useState<any>(null);
    const [projectsData, setProjectsData] = React.useState<any>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    
    const handleResourceDetails = () => {
        const params = {
            resourceId: resourceData?.id
          };
        const queryString = new URLSearchParams(params).toString();          
        navigate(`/resource-project-detail?${queryString}`)
    }

      const handleStateAnchor = () => {
        navigate("/update-resource", { 
            state: resourceData
          })
      };

      const handleAddResource = () => {
        navigate("/add-resource")
      }

      const handleBackButton = () => {
        navigate("/resources")
      }
  
    
    function stringAvatar(name: string) {
        return {
            sx: {
            width:40,
            height:40,
            bgcolor: '#5257C71A',
            color: '#5257C7B2',
            border: '1px solid #5257C7B2',
            borderRadius: '50%',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '19.2px'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const fetchResource = async (resourceId:any) => {
        const api_url = process.env.REACT_APP_API_URL;
        const url = api_url + `resource/${resourceId}`
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);

        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        };

        setloader(true);
        await fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            setResourceData(result.data)
            setProjectsData(result.projects_list)
            setloader(false)
        })
        .catch((error) => {
            toast.error(error)
            setloader(false)
        });      
    }

    const handleDeleteResource = () =>{
        setOpenDialog(true);
    }

    const handleClose = () => {
        setOpenDialog(false);
    };
    
    const handleDelete = async () => {
        const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);

        const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        };
        setloader(true);
        const api_url = process.env.REACT_APP_API_URL;    
        await fetch(api_url + `resource/${resourceData.id}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            toast.success("Resource Deleted Successfully")        
            setloader(false)
            setOpenDialog(false);
            navigate("/resources")
        })
        .catch((error) => {
            toast.error(error)
            setloader(false)
            setOpenDialog(false);
        });
    };

    useEffect( () => {
        const searchParams = new URLSearchParams(location.search);
        const resourceId = searchParams.get('resourceId');
        if (resourceId) {
            fetchResource(resourceId);
        }

    }, [])
    
    
  return (
    <Grid spacing={0}  className='mainApp'>
            <ResponsiveAppBar />  
            <Box>                
                <Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={10}>
                        <Grid container >
                            <Grid item md={7}>

                                <div className='titleBar' style={{display:'flex', alignItems:'center'}}>
                                    
                                    <div onClick={handleBackButton} style={{cursor:'pointer'}}>
                                        <ArrowBackIcon />
                                    </div>

                                    <span style={{marginLeft: "20px"}}>Resource Details</span>
                                </div>
                            </Grid>
                            <Grid item md={3}>
                            {/* <TextField
                                className='search-field'
                                placeholder='Search'
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                                    }}
                                /> */}
                                
                            </Grid>
                            
                            <Grid item md={2}>
                                <Button className='add-resource' onClick={handleAddResource}>
                                    Add Resources
                                </Button>                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                </Grid>
                    
            </Box>   



            {loader && !resourceData ? <div style={{display:'flex',justifyContent:'center'}}><CircularProgress size={24} color="inherit" /></div> : 
            <Box sx={{ width: '100%', marginTop: "30px" }}>
              <Paper sx={{ width: '100%', mb: 2 , borderRadius:"30px"}}>
                <Grid container>
                    <Grid item md={8}>
                        <div className='resource-card-detail'>
                            
                            <Grid container>
                                <Grid item md={8}>
                                    <div className='resource-name-dash'>
                                        <Avatar {...stringAvatar(`${resourceData?.firstName} ${resourceData?.lastName}`)} className='resource-avatar' />
                                        <div className='rsc-name'>
                                            {resourceData?.firstName} {resourceData?.lastName}
                                            <br></br>
                                            <div className='rsc-designation'>{resourceData?.jobTitle?.name}</div>
                                        </div>   
                                    </div>
                                
                                </Grid>
                                <Grid item md={4}>
                                    <Button className='rsc-delete-btn' onClick={handleDeleteResource}>Delete</Button>
                                    <Button className='rsc-edit-btn' onClick={handleStateAnchor} style={{marginRight:'10px'}}>Edit Profile</Button>
                                </Grid>
                            </Grid>
                            
                            <Box className='rsc-personal-info-sec'>
                                <div className='box-heading'>Personal Information</div>
                                <div className='box-sec'>
                                    <Grid container>
                                        <Grid item md={3}>
                                            <div className='box-label'>Email</div>
                                            <div className='box-text'>{resourceData?.email}</div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='box-label'>Gender</div>
                                            <div className='box-text'>{resourceData?.gender}</div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='box-label'>DOJ</div>
                                            <div className='box-text'>{getDateFromString(resourceData?.dateOfJoining)}</div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>


                            <Box className='rsc-personal-info-sec'>
                                <div className='box-heading'>Overview</div>
                                <div className='box-sec'>
                                    <Grid container>
                                        <Grid item md={3}>
                                            <div className='box-label'>Job Title</div>
                                            <div className='box-text'>{resourceData?.jobTitle?.name}</div>
                                        </Grid>
                                        {/* <Grid item md={3}>
                                            <div className='box-label'>Permission Role</div>
                                            <div className='box-text'>{resourceData?.jobTitle}</div>
                                        </Grid> */}
                                        <Grid item md={3}>
                                            <div className='box-label'>Time Zone</div>
                                            <div className='box-text'>{resourceData?.timeZone}</div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='box-label'>Is Lead?</div>
                                            <div className='box-text'>{getBoolenYesOrNO(resourceData?.isLead)}</div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='box-label'>User ID</div>
                                            <div className='box-text'>{resourceData?.userID}</div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>


                            <Box className='rsc-personal-info-sec'>
                                <div className='box-heading'>Work Details</div>
                                <div className='box-sec'>
                                    <Grid container>
                                        <Grid item md={3}>
                                            <div className='box-label'>Department</div>
                                            <div className='box-text'>{resourceData?.department?.name}</div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='box-label'>Manager</div>
                                            <div className='box-text'>{resourceData?.manager?.firstName} {resourceData?.manager?.lastName}</div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='box-label'>Status</div>
                                            <div className='box-text'>
                                                <span className='rsc-status-perm'>{resourceData?.employmentStatus}</span>
                                                {/* <span className='rsc-status-prob'>Probation</span> */}
                                            </div>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className='box-label'>Total Hours</div>
                                            <div className='box-text'>{resourceData.totalHours}</div>
                                        </Grid>

                                    </Grid>

                                    <div className='rsc-stack-sec'>
                                        <div className='box-label' style={{marginBottom: "20px"}}>Stacks</div>
                                        {resourceData?.Stacks.map((val: any) => <span className='rsc-stack'>{val?.name}</span>)}
                                        {/* <span className='rsc-stack'>{resourceData?.stacks}</span> */}
                                        
                                    </div>
                                </div>
                            </Box>




                        </div>
                    </Grid>
                    <Grid item md={4}>
                        <div className='rsc-availability-sec'>
                            <Grid container>
                                <Grid item md={6}>
                                    <div className='box-label'>Assigned Projects</div>
                                </Grid>
                                <Grid item md={6}>
                                    <Button className='rsc-edit-btn' onClick={handleResourceDetails}>Details</Button>
                                </Grid>
                                
                            </Grid>

                            <div className='rsc-proj-li'>
                            {projectsData?.map((val: any) => (
                                <div>
                                    <div className='proj-li'>{val?.projectName}</div>
                                </div>

                            ))}
                            </div>

                            <br></br>
                            <br></br>

                            {resourceData?.isLead ? (
                                <>
                                <Grid container>
                                    <Grid item md={6}>
                                        <div className='box-label'>Team</div>
                                    </Grid>
                                    
                                </Grid>                            
                                <div className='rsc-proj-li'>
                                    {resourceData?.teamEmployees.map((val: any) => <div className='proj-li'>{val?.firstName + ' ' + val?.lastName}</div>)}                                                            
                                </div>
                                </>
                            ) : (
                                <></>
                            )}
                            
                        </div>
                    </Grid>
                </Grid>
                        {/* Delete Confirmation Dialog */}
                <Dialog
                    open={openDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete "{resourceData?.firstName} {resourceData?.lastName}"?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="error" autoFocus>
                        Delete
                    </Button>
                    </DialogActions>
                </Dialog>
              </Paper>
            </Box>}
    
    </Grid>

  );
}

export default ResourcesDetail;
