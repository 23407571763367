import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { Button, CircularProgress, Fade, TextField, Typography,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
 } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';


const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Department',
    },
    {
      id: 'edit',
      numeric: false,
      disablePadding: false,
      label: 'Action',
    }
    
];


    

export default function DepartmentTable(props: any) {

  const Departments = props.Departments;
  const handleDelete = props.handleDelete;

  const [loader, setloader] = useState(false);
  const [updateDepartmentName, setUpdateDepartmentName] = useState("")
  const [updateDepartmentIndex, setUpdateDepartmentIndex] = useState("");
  const [updateDepartment, setUpdateDepartment] = useState({
    id: '',
    name: ''
  });

  const [Department, setDepartmentData] = useState([{
    id: '',
    name: '',
    createdAt: '',
    updatedAt: ''
  }]);


  function EnhancedTableHead() {

    return (
      <TableHead>
        <TableRow className='table-header'>
  
          <TableCell className='table-header-cell'
          ></TableCell>
          {headCells.map((headCell) => (
            <TableCell
              className='table-header-cell'
              key={headCell.id}                          
            >
            {headCell.label}
          </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  React.useEffect( () => {
    setloader(true);
    
    setDepartmentData(Departments)
    setloader(false);
  }, [Departments])

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  
  const handleUpdateDepartment = (row: any) => {    
    setUpdateDepartment(row);
    setUpdateDepartmentName(row.name)
    handleOpen();  
  }

  
  const handleDeleteStack = (row: any, index: any) => {    
    setUpdateDepartment(row);
    setUpdateDepartmentIndex(index);
    setUpdateDepartmentName(row.name)
    handleOpenDialog();  
  }

  const handleDeleteStackDialog = async () => {
    
    await handleDelete(updateDepartmentIndex, updateDepartment);
    handleCloseDialog();
  }

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeUpdateDepartment = (e: any) => {
    setUpdateDepartmentName(e.target.value);

  }

  const handleUpdateDepartmentAPI = async () => {    

    const token = localStorage.getItem('token');
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "token " + token);

        const payload = JSON.stringify({        
          "name": updateDepartmentName
        })

        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: payload
          };

        
        const api_url = process.env.REACT_APP_API_URL;                    
        await fetch(api_url + "department/" + updateDepartment?.id, requestOptions)
        .then((response) => response.json())
        .then((result) => {            
          const res = result        
          let mainrowsData = props.mainRows;
          const setRowsData = props.setRows;
          const setMainRowsData = props.setMain;
  
          let new_row = {
              id: res?.id, name: res?.name
          }
          
          let new_rows = mainrowsData;

          new_rows.map(((item: any, index: any) => {
            if (item?.id === new_row?.id){
              item.name = new_row.name
            }            
          }))

          

          setRowsData(mainrowsData)  
          setMainRowsData(mainrowsData)  
          
          toast.success("Sucess");
          handleClose();  
  
        }).catch((error) => {
            console.log(error);
        })

    
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 10,
    p: 4,
  };

  return (
    <Box sx={{ width: '100%', borderRadius:"30px" }}>
      {loader ? <div  style={{display:'flex',justifyContent:'center'}}>
        <CircularProgress size={24} color="inherit" /> 
      </div>:  
      <Paper sx={{ width: '100%', mb: 2 , borderRadius:"30px"}}>
        <TableContainer>
          <Table
            sx={{ minWidth: 1000 , borderRadius:"30px"  }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead   />
            <TableBody>
              {Department?.map((row : any, index: any) => {               
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow                    
                    hover
                    key={row?.id}
                    sx={{ cursor: 'pointer',borderBottom: 'none !important' }}                                     
                  >                   
                    <TableCell className='table-cell-row'></TableCell>
                    <TableCell
                      className='table-cell-row-name'
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <div>
                        {row?.name}
                      </div>
                    </TableCell>
                    
                    <TableCell  style={{paddingLeft: '0px'}}>
                      
                    <Tooltip title="Edit">
                      <IconButton onClick={() =>  handleUpdateDepartment(row)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                      <Modal     
                        aria-labelledby="spring-modal-title"
                        aria-describedby="spring-modal-description"                  
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition                        
                        sx={{
                          '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                          }, '& .MuiBox-root': {
                            boxShadow: '0px 12px 31px -22px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 9px 16px -64px rgba(0, 0, 0, 0.12)'
                          }
                        }}
                      >
                        <Fade in={open}>
                          <Box sx={style}>
                            <Typography id="spring-modal-title" variant="h6" component="h2">
                              Update Department
                            </Typography>

                            <TextField id="outlined-basic" label="Department" className='field-update' variant="outlined"  
                              value={updateDepartmentName}  onChange={handleChangeUpdateDepartment}/>
                            <Button className='add-resource' onClick={handleUpdateDepartmentAPI}>Update</Button>
                          </Box>
                        </Fade>
                      </Modal>

                      <Tooltip title="Delete">
                        <IconButton onClick={() =>  handleDeleteStack(row, index)}>
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>

                    </TableCell>


                     {/* Delete Confirmation Dialog */}
                     <Dialog
                        open={openDialog}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                          '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                          }, '& .MuiPaper-root': {
                            boxShadow: '0px 12px 31px -22px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 9px 16px -64px rgba(0, 0, 0, 0.12)'
                          }
                        }}
                      >
                        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the Job Title "{updateDepartmentName}"?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={handleDeleteStackDialog} color="error" autoFocus>
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>

                  </TableRow>
                );
              })}
            
            </TableBody>
          </Table>
        </TableContainer>
      </Paper> }     
    </Box>

  );
}
